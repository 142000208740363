import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const Klaviyo = ({ config }) => {
	if (!config || !config.formElement) {
		return null;
	}
	return (
		<div className="container max-w-md mb-3 md:mb-4">
			{config.heading && (
				<Typography className="mb-2 text-center md:mb-4" component="h2" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			<div dangerouslySetInnerHTML={{ __html: config.formElement }}></div>
		</div>
	);
};

Klaviyo.propTypes = {
	config: PropTypes.object,
};

export default Klaviyo;
