import Loading from '/components/Loading';
import ProductCarousel from '/components/lp/sections/shared/ProductCarousel';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getProductsShopify } from '/services/products';

import { useEffect, useState } from 'react';

const CollectionCarousel = ({ config }) => {
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function getProducts() {
			setLoading(true);
			const productsResponse = await getProductsShopify(config.collectionHandle, 18);

			if (productsResponse && productsResponse.hits) {
				setProducts(productsResponse.hits);
				setLoading(false);
			}
		}

		if (config.collectionHandle) {
			getProducts();
		}
	}, [config.collectionHandle]);

	const splitProductsArray = config.maxProducts ? products?.slice(0, config.maxProducts) : products;
	if (config.showEndcap) {
		splitProductsArray.push({
			endcap: true,
			endcapCopy: config.endcapCopy,
			endcapCta: config.endcapCta,
			endcapDestination: `/collections/${config.collectionHandle}`,
		});
	}
	if (loading) {
		return (
			<div className="container max-w-7xl mb-4 px-2 h-[438px] w-full flex items-center align-middle justify-center">
				<Loading height="50px" width="50px" />
			</div>
		);
	}
	return (
		<div className="container max-w-7xl mb-4 px-2">
			{config.heading && (
				<Typography className="text-center mb-4" component="h1" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			<ProductCarousel products={splitProductsArray} />
		</div>
	);
};

CollectionCarousel.propTypes = {
	config: PropTypes.object,
};

export default CollectionCarousel;
